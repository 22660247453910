import { TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import typography from '../../../theme/typography';

const DelayInputField: React.FC<{
  dataTestId?: string;
  max: number;
  min: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  step?: number;
  unit?: string;
  value: number;
}> = ({ dataTestId, max, min, onChange, step, unit, value }) => {
  const { t } = useTranslation('alertRuleSensorTypes');

  return (
    <>
      <TextField
        InputProps={{
          style: {
            marginTop: 0,
          },
        }}
        inputProps={{
          'data-testid': dataTestId,
          max,
          min,
          step: step || 1,
          style: {
            borderRadius: '8px',
            ...typography['text-xs-bold'],
            padding: '0 0 0 20px',
          },
        }}
        onChange={onChange}
        style={{ marginRight: '4px', width: '70px' }}
        type="number"
        value={value}
      />
      <Typography variant="text-xs">
        {unit || t('measurement.minutes')}
      </Typography>
    </>
  );
};

export default DelayInputField;
